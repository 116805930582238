import React, { useRef, useEffect, useCallback, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { PiWalletLight, PiAppWindowDuotone, PiBarcodeLight, PiArchiveDuotone } from "react-icons/pi";
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import "./sidebar.scss";
import UsersForm from '../Users/UsersForm';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';



const Sidebar = ({ user }) => {
  const refSidebar = useRef(null);
  const refLockIcon = useRef(null);
  const refClose = useRef(null);
  const refSubMenu3Items = useRef(null);
  const refSubMenu3Icon = useRef(null);
  const refFessesIcon = useRef(null);
  const refFessesItems = useRef(null);
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [isComplianceOpen, setIsComplianceOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // Por defecto, puede ser 'success' o 'error'


  useEffect(() => {
   
    if (location.pathname.includes('/settings')) {
      toggleSubMenu00(true);
    }
    if (location.pathname.includes('/feeses')) {
      toggleSubMenu(refFessesIcon, refFessesItems, true); // Abrir el menú de "Feeses"
    }
  }, [location.pathname]);

  const toggleSubMenu00 = useCallback(() => {
    toggleSubMenu(refSubMenu3Icon, refSubMenu3Items);
  }, []);

  const handleSave = () => {
    closeModal(); // Cierra el modal
    setSnackbarMessage('Your profile is updated'); // Establece el mensaje del snackbar
    setSnackbarOpen(true); // Muestra el snackbar
  };

  const toggleSubMenu = useCallback((iconRef, itemsRef, force) => {




    if (iconRef.current && itemsRef.current) {
      const currentClassName = iconRef.current.className;
      const currentClassNameItems = itemsRef.current.className;

      if (currentClassNameItems.includes('hidden') || force) {
        iconRef.current.className = currentClassName.replace('bx-chevron-right', 'bx-chevron-down'); // Abre
        itemsRef.current.classList.remove('hidden');
      } else {
        iconRef.current.className = currentClassName.replace('bx-chevron-down', 'bx-chevron-right'); // Cierra
        itemsRef.current.classList.add('hidden');
      }
    }
  }, []);

  const handleComplianceClick = () => {
    setIsComplianceOpen(!isComplianceOpen); // Cambia el estado de apertura
    toggleSubMenu(refFessesIcon, refFessesItems); // Alterna el submenú
  };


  /*

  const handleProfileClick = () => {
    setIsProfileOpen(!isProfileOpen); // Cambia el estado de apertura
    toggleSubMenu(refFessesIcon, refFessesItems); // Alterna el submenú
  };

*/


const handleSnackbarClose = () => {
  setSnackbarOpen(false); // Oculta el snackbar
};




const toggleLock = useCallback(() => {
  if (refSidebar.current && refLockIcon.current) {
    // Alterna la clase 'locked' para cambiar el tamaño
    const isLocked = refSidebar.current.classList.toggle("locked");

    // Cambiar el icono dependiendo del estado del sidebar
    if (isLocked) {
      refLockIcon.current.classList.replace('bx-lock-alt', 'bx-lock-open-alt');

      // Cerrar todos los submenús al bloquear el sidebar
      const subMenus = refSidebar.current.querySelectorAll('.sub_menu_item');
      subMenus.forEach((submenu) => submenu.classList.add('hidden'));

      const icons = refSidebar.current.querySelectorAll('.bx-chevron-down');
      icons.forEach((icon) =>
        icon.classList.replace('bx-chevron-down', 'bx-chevron-right')
      );
    } else {
      refLockIcon.current.classList.replace('bx-lock-open-alt', 'bx-lock-alt');
    }
  }
}, []);




  const hideSidebar = useCallback(() => {
    if (refSidebar.current && refSidebar.current.classList.contains('hoverable')) {
      refSidebar.current.classList.add('close');
    }
  }, []);

  const showSidebar = useCallback(() => {
    if (refSidebar.current && refSidebar.current.classList.contains('hoverable')) {
      refSidebar.current.classList.remove('close');
    }
  }, []);

  const logout = useCallback(() => {
    axios.post(process.env.REACT_APP_URL_API + 'logout', {}, {
      withCredentials: true
    }).then(function (response) {
      // Handle successful logout
    }).catch(function (error) {
      console.error('Logout error:', error);
    });
  }, []);



  


  const handleProfileClick = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_URL_API + 'profile', { 
        withCredentials: true 
      });
      setSelectedUser(response.data); // Set the selected user based on the response
      setIsModalOpen(true);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      alert('Failed to fetch profile. Please try again.');
    }
  };


  


  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const hasPermission = useCallback((tag) => {
    if (user && user.permissions) {
      const isViewer = user.permissions.some(permission => permission.tag === 'CORE_VIEWER');
      if (isViewer) {
        return user.permissions.some(permission => permission.tag === tag || permission.tag === 'CORE_VIEWER');
      }
      const isSuperAdmin = user.permissions.some(permission => permission.tag === 'SUPERADMIN');
      if (isSuperAdmin) {
        return true;
      }
      return user.permissions.some(permission => permission.tag === tag);
    }
    return false; // No se encontraron permisos
  }, [user]);

  if (!user) {
    return null;
  }

  const isActive = (path) => {
    const currentPath = location.pathname;
   
    return currentPath === path;
  };

  return (
    <>
      <nav ref={refSidebar} className="sidebar" onMouseLeave={hideSidebar} onMouseEnter={showSidebar}>
        <div className="logo_items flex">
          <span className="nav_image"></span>
          <span className="logo_name">Core</span>
          <i ref={refLockIcon} className='bx bx-lock-alt' id="lock-icon" title="Unlock sidebar" onClick={toggleLock}></i>
          <i ref={refClose} className='bx bx-x' id="sidebar-close"></i>
        </div>

        <div className="menu_container">
          <div className="menu_items">
            <ul className="menu_item">
              <div className="user_info">
                <p style={{ color: '#fff', opacity: 0.9, padding: '10px', textAlign: 'left', fontStyle: 'italic',left:'6px',position:'relative' }}>
               
                
                

                          <li className="item">
            <p
              style={{
                position: 'relative',
                left: '-15px',
                display: 'flex',
                alignItems: 'center',
              }}
              className="link"
              onClick={handleProfileClick}
            >
              <i className="bx bx-user-circle"    style={{marginRight:"-8px" }}></i>
              <span>{user.email}</span>
            </p>
          </li>



                </p>
              </div>

              <div className="menu_title flex">
                <span className="title">Dashboard</span>
                <span className="line"></span>
              </div>

              {(hasPermission('CORE_USERS_MNU') || hasPermission('CORE_FEESES_MNU') ) && (  //  un core fees tiene acceso a la solapa de user al menos a esa 
                <li className={`item ${isActive("/users") ? "active" : ""}`}>
                  <Link to="/users" style={{ textDecoration: "none" }}>
                    <p className="link flex" >
                      <i className='bx bx-user'></i>
                      <span>Users</span>
                    </p>
                  </Link>
                </li>
              )}



{hasPermission('CORE_CHECKS_MNU') && ( // Verificar el permiso antes de renderizar
  <li className={`item ${isActive("/checks") ? "active" : ""}`}>
    <Link to="/checks" style={{ textDecoration: "none" }}>
      <p className="link flex">
      <i className="bx bx-transfer"></i>
        <span>Checks</span>
      </p>
    </Link>
  </li>
)}






        {hasPermission('CORE_USR_COMPLIANCE')  && (  //  un core fees tiene acceso a la solapa de user al menos a esa 



            // Cambia el evento onClick en el menú de Compliance
            <li className={`item `}>
              <p className="link flex" onClick={handleComplianceClick}>
                <i><PiWalletLight /></i>
                <span>Compliance</span>
                <i ref={refFessesIcon} className='ml-auto bx bx-chevron-right'></i>
              </p>
              <ul className={`sub_menu_item ${isComplianceOpen ? "" : "hidden"}`} ref={refFessesItems}>
                <Link to="/verifications" style={{ textDecoration: "none" }}>
                <li className={`item ${isActive("/verifications") ? "active" : ""}`}>
                  <p className="link flex">
                    <i className='bx bx-user'></i>
                    <span>Verifications</span>
                  </p>
                  </li>
                </Link>



                <Link to="/settings/profiles" style={{ textDecoration: "none" }}>
                <li className={`item ${isActive("/settings/profiles") ? "active" : ""}`}>
                  <p className="link flex">
                    <i className='bx bx-user'></i>
                    <span>Profiles</span>
                  </p>
                  </li>
                </Link>
                



<Link to="/settings/rules" style={{ textDecoration: "none" }}>
                <li className={`item ${isActive("/settings/rules") ? "active" : ""}`}>
                  <p className="link flex">
                  <i className='bx bx-book'></i>
                    <span>Rules</span>
                  </p>
                  </li>
                </Link>


                <Link to="/settings/alerts" style={{ textDecoration: "none" }}>
                <li className={`item ${isActive("/settings/alerts") ? "active" : ""}`}>
                  <p className="link flex">
                  <i className="bx bx-alarm"></i> {/* Ícono de alarma */}
                    <span>Alerts</span>
                  </p>
                  </li>
                </Link>



{/*

                <Link to="/settings/alerts" style={{ textDecoration: "none" }}>
                <li className={`item ${isActive("/settings/alerts") ? "active" : ""}`}>
                  <p className="link flex">
                  <i className='bx bx-bell'></i>

                    <span>Alerts</span>
                  </p>
                  </li>
                </Link>

*/}




              </ul>
            </li>

              )}






            { (hasPermission('CORE_FEESES_MNU') ) && (
              <li className={`item ${isActive("/feeses") ? "active" : ""}`}>
                <p className="link flex" onClick={() => toggleSubMenu(refFessesIcon, refFessesItems)}>
                  <i><PiWalletLight /></i>
                  <span>Feeses</span>
                  <i ref={refFessesIcon} className='ml-auto bx bx-chevron-right'></i>
                </p>
                <ul className={`sub_menu_item ${isActive("/feeses") ? "" : "hidden"}`} ref={refFessesItems}>

                
            { (hasPermission('CORE_FEESES_DEFAULT_MNU') ) && (

                  <Link to="/feeses/defaultsettings" style={{ textDecoration: "none" }}>
                    <li className={`item ${isActive("/feeses/defaultsettings") ? "active" : ""}`}>
                      <p className="link flex">
                        <i className='bx bx-cog'></i>
                        <span>Default Settings</span>
                      </p>
                    </li>
                  </Link>
            )}

                { (hasPermission('CORE_FEESES_CUSTOM_MNU') ) && (
              
                  <Link to="/feeses/customsettings" style={{ textDecoration: "none" }}>
                    <li className={`item ${isActive("/feeses/customsettings") ? "active" : ""}`}>
                      <p className="link flex">
                        <i className='bx bx-info-circle'></i>
                        <span>Custom Settings</span>
                      </p>
                    </li>
                  </Link>

                  )}
                
                </ul>
              </li>
              )}


              {(hasPermission('CORE_SETTINGS_MNU') )  && (
                <li className={`item ${isActive("/settings") ? "active" : ""}`}>
                  <p className="link flex" onClick={toggleSubMenu00}>
                    <i><PiWalletLight /></i>
                    <span>Settings</span>
                    <i ref={refSubMenu3Icon} className='ml-auto bx bx-chevron-right'></i>
                  </p>
                  <ul className={`sub_menu_item ${isActive("/settings") ? "" : "hidden"}`} ref={refSubMenu3Items}>



                  {(hasPermission('CORE_ELEMTYPE_MNU') ) && (
                      <Link to="/settings/types" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/types") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-info-circle'></i>
                            <span>Element Types</span>
                          </p>
                        </li>
                      </Link>
                    )}


              {(hasPermission('CORE_ELEMSUBTYPE_MNU') ) && (
                      <Link to="/settings/subtypes" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/subtypes") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-file'></i>
                            <span>Element SubTypes</span>
                          </p>
                        </li>
                      </Link>
                    )}


                    {(hasPermission('CORE_SETTINGS_APPS_MNU') ) && (
                      <Link to="/settings/apps" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/apps") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-payment'><PiAppWindowDuotone /></i>
                            <span>Apps</span>
                          </p>
                        </li>
                      </Link>
                    )}

                    {(hasPermission('CORE_SETTINGS_ROLES_MNU') )  && (
                      <Link to="/settings/roles" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/roles") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-user-check'></i>
                            <span>Roles</span>
                          </p>
                        </li>
                      </Link>
                    )}



   


                    {hasPermission('CORE_USR_ROLES') && (
                      <Link to="/users_roles" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/users_roles") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-user'></i>
                            <span>Users Roles</span>
                          </p>
                        </li>
                      </Link>
                    )}

                    {hasPermission('CORE_FUNCT_MNU') && (
                      <Link to="/settings/functionalities" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/functionalities") ? "active" : ""}`}>
                          <p className="link flex">
                            <i className='bx bx-service'><PiArchiveDuotone /></i>
                            <span>Functionalities</span>
                          </p>
                        </li>
                      </Link>
                    )}



          {hasPermission('SUPERADMIN') && (
                      <Link to="/settings/scopes" style={{ textDecoration: "none" }}>
                        <li className={`item ${isActive("/settings/scopes") ? "active" : ""}`}>
                          <p className="link flex">
                          <i className="bx bx-devices"></i>

                            <span>Scopes</span>
                          </p>
                        </li>
                      </Link>
                    )}







                  </ul>
                </li>
              )}
            </ul>
          </div>
          <div className="bottom-content">
            <Link to="/login" style={{ textDecoration: "none" }} onClick={logout}>
              <li className="item">
                <p className="link flex">
                  <i className='bx bx-log-out icon'></i>
                  <span>Logout</span>
                </p>
              </li>
            </Link>
          </div>
        </div>
      </nav>


      <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}  // Cambiar a la derecha
    >
      <Alert 
        onClose={() => setSnackbarOpen(false)} 
        severity={snackbarSeverity} 
        sx={{ 
          width: '100%', 
          backgroundColor: snackbarSeverity === 'success' ? '#4caf50' : '#f44336', // Verde para éxito, rojo para error
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white', // Color blanco para el icono
          },
        }}
        iconMapping={{
          success: <CheckCircleIcon sx={{ fontSize: 20 }} />,
          error: <ErrorIcon sx={{ fontSize: 20 }} />
        }}
      >
        {snackbarMessage}
      </Alert>
    </Snackbar>
 
      <Dialog open={isModalOpen} onClose={closeModal}>
        <DialogTitle style={{ position: 'relative' }}>
          User Profile
          <CloseIcon
            onClick={closeModal}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              cursor: 'pointer',
              color: '#555',
            }}
          />
        </DialogTitle>
        <DialogContent>
          <UsersForm
            user={selectedUser?.data}
            onSave={handleSave} // Usa handleSave en lugar de closeModal
            onCancel={closeModal}
          />
        </DialogContent>
      </Dialog>


    </>
  );
};

export default Sidebar;
